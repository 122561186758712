<template>
  <information-app-modal
    v-model="model"
    button-title="Continue"
    @close="closeModal"
    @confirm="onDelete"
    should-display-close-button
    :icon="ICON_TYPE.HOURGLASS"
  >
    <h1 class="title">{{ $t('CardPaymentExpired.HeadingText').value }}</h1>
    <!-- <h1 class="title">Your card has expired</h1> -->

    <p>{{ $t('CardPaymentExpired.DescriptionParagraphText').value }}</p>

    <!-- <p>Your card is no longer valid, please add a new card and delete this one from your list</p> -->
    <template #footer>
      <AppButton @click="onDelete">
        {{ $t('CardPaymentExpired.DeleteButtonText').value }}
      </AppButton>
      <AppButton theme="secondary" @click="closeModal">
        {{ $t('CardPaymentExpired.KeepItForNowButton').value }}
      </AppButton>
      <!-- Keep it, for now -->
    </template>
  </information-app-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import { useRouter } from '@galileo/composables/useRouter'
import InformationAppModal, {
  ICON_TYPE,
} from '@galileo/components/InformationAppModal/InformationAppModal'

import { useVModel, AppDialog, AppDialogHeader, AppDialogFooter, AppButton } from '@oen.web.vue2/ui'

import { usePaymentsStore, useI18nStore } from '@galileo/stores'

export default {
  name: 'CardPaymentExpired',
  components: {
    AppDialog,
    AppDialogHeader,
    AppDialogFooter,
    AppButton,
    InformationAppModal,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)
    const paymentsStore = usePaymentsStore()
    const router = useRouter()
    const confirming = ref(false)
    const selectedPaymentMethod = paymentsStore.selectedPaymentMethod

    const closeModal = () => {
      model.value = false
    }
    const onDelete = () => {
      router.replace({ path: 'delete' })
    }

    return {
      model,
      confirm,
      confirming,
      selectedPaymentMethod,
      $t,
      onDelete,
      closeModal,
      ICON_TYPE,
    }
  },
}
</script>

<style scoped>
p {
  @apply text-base;
}

.card {
  @apply p-12;
}

.card-header {
  padding: 0 0 2rem 0;
}

::v-deep {
  .card-header-title {
    @apply h-auto mt-6;
  }

  .card-content .card-content-block {
    @apply mb-6;
  }
}
</style>
